import { Card, CardBody } from '@paljs/ui/Card';
import { InputGroup } from '@paljs/ui/Input';
import { Button } from '@paljs/ui/Button';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import axios from 'axios';
import { EvaIcon } from '@paljs/ui/Icon';
import { GlobalStateData } from '../../storage/GlobalDataProvider';
import '../../styles/common.css';
import Select from '@paljs/ui/Select';
import {
  requireCheck,
  resetRequired,
  pullDownReset,
  baseUri,
  showLoader,
  hideLoader,
  numFormatter,
  authCheck,
  dateFormat,
  dateGreaterOrEqual,
  buildQuery,
} from '../../utils/utils';
import { getFiltered } from '../../utils/table';
import DataTable from 'react-data-table-component';
import ReactPaginate from 'react-paginate';
import { PDFViewer, PDFDownloadLink, pdf } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { CSVLink, CSVDownload } from 'react-csv';
import PdfGenerator from '../../components/PdfGenerator';
import moment from 'moment';

const ReceiptPOCReport = () => {
  const [tableData, setTableData] = useState([]);
  const [tableFilter, setTableFilter] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [from, setFrom] = useState(dateFormat(new Date()));
  const [to, setTo] = useState(dateFormat(new Date()));
  const [pdfData, setPdfData] = useState({
    from: '',
    to: '',
    tableTitles: [],
    tableContents: [],
  });
  const filterColumns: Array<String> = [
    'machine',
    'DYES',
    'CHEMICALS',
    'PACKING',
    'CONSUMBALES',
    'ENGINEERING_ITEM',
    'ELECTICAL_ITEM',
    'SERVICE_ITEMS',
    'CAPITAL_ITEMS',
    'FACTORY_ITEMS',
  ];
  const getLocaleNumber = (num) => {
    let currNum = +num;
    return currNum.toLocaleString('en-IN', { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  };
  const filterChange = (searchInput: any, newData = false) => {
    setTableFilter(searchInput);
    let available: any = [];
    if (newData) {
      available = newData;
    } else {
      available = itemData;
    }
    if (searchInput) {
      let filteredData = getFiltered(searchInput, available, filterColumns, true);
      setTableData(filteredData);
    } else {
      setTableData(available);
    }
  };
  const columns = [
    {
      name: 'MACHINE',
      selector: (row) => `${row.machine}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Category',
      selector: (row) => `${row?.Category || ''}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Dyes',
      selector: (row) => `${row.DYES}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Chemicals',
      selector: (row) => `${row.CHEMICALS}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Packing',
      selector: (row) => `${row.PACKING}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Consumbales',
      selector: (row) => `${row.CONSUMBALES}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Engineering Item',
      selector: (row) => `${row.ENGINEERING_ITEM}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Electical Item',
      selector: (row) => `${row.ELECTICAL_ITEM}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Service Items',
      selector: (row) => `${row.SERVICE_ITEMS}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Capital Items',
      selector: (row) => `${row.CAPITAL_ITEMS}`,
      sortable: true,
      maxWidth: '45%',
    },
    {
      name: 'Factory Items',
      selector: (row) => `${row.FACTORY_ITEMS}`,
      sortable: true,
      maxWidth: '45%',
    },
  ];

  const pdfTableTitles = [
    'Machine',
    'Dyes',
    'CHEM',
    'PACKING',
    'CONSUM',
    'ENG_ITM',
    'ELEC_ITM',
    'SERV_ITM',
    'CAP_ITM',
    'FAC_ITE',
    'Tot',
  ];
  const getApi = async () => {
    showLoader();
    let res: any = await axios.get(`${baseUri()}reports/machinewise-itemwise-abstract?from=${from}&to=${to}`);
    res = (await res.data.data) || [];
    const pdfContent = [];
    const totalContent = {
      DYES: 0,
      CHEMICALS: 0,
      PACKING: 0,
      CONSUMBALES: 0,
      ENGINEERING_ITEM: 0,
      ELECTICAL_ITEM: 0,
      SERVICE_ITEMS: 0,
      CAPITAL_ITEMS: 0,
      FACTORY_ITEMS: 0,
      total: 0,
    };
    for (let i = 0; i < res.length; i++) {
      const currMachineName = res[i].machine;
      const category = {
        DYES: ' ',
        CHEMICALS: ' ',
        PACKING: ' ',
        CONSUMBALES: ' ',
        ENGINEERING_ITEM: ' ',
        ELECTICAL_ITEM: ' ',
        SERVICE_ITEMS: ' ',
        CAPITAL_ITEMS: ' ',
        FACTORY_ITEMS: ' ',
      };
      while (i < res.length && res[i].machine === currMachineName) {
        if (+res[i]['DYES']) {
          category.DYES = res[i]['DYES'];
        }
        if (+res[i]['CHEMICALS']) {
          category.CHEMICALS = res[i]['CHEMICALS'];
        }
        if (+res[i]['PACKING']) {
          category.PACKING = res[i]['PACKING'];
        }
        if (+res[i]['CONSUMBALES']) {
          category.CONSUMBALES = res[i]['CONSUMBALES'];
        }
        if (+res[i]['ENGINEERING_ITEM']) {
          category.ENGINEERING_ITEM = res[i]['ENGINEERING_ITEM'];
        }
        if (+res[i]['ELECTICAL_ITEM']) {
          category.ELECTICAL_ITEM = res[i]['ELECTICAL_ITEM'];
        }
        if (+res[i]['SERVICE_ITEMS']) {
          category.SERVICE_ITEMS = res[i]['SERVICE_ITEMS'];
        }
        if (+res[i]['CAPITAL_ITEMS']) {
          category.CAPITAL_ITEMS = res[i]['CAPITAL_ITEMS'];
        }
        if (+res[i]['FACTORY_ITEMS']) {
          category.FACTORY_ITEMS = res[i]['FACTORY_ITEMS'];
        }
        i++;
      }
      let currTotal = 0;
      for (let key in category) {
        currTotal += +category[key];
        totalContent[key] += +category[key];
      }
      totalContent.total += currTotal;
      pdfContent.push([
        currMachineName,
        getLocaleNumber(category.DYES),
        getLocaleNumber(category.CHEMICALS),
        getLocaleNumber(category.PACKING),
        getLocaleNumber(category.CONSUMBALES),
        getLocaleNumber(category.ENGINEERING_ITEM),
        getLocaleNumber(category.ELECTICAL_ITEM),
        getLocaleNumber(category.SERVICE_ITEMS),
        getLocaleNumber(category.CAPITAL_ITEMS),
        getLocaleNumber(category.FACTORY_ITEMS),
        getLocaleNumber(currTotal),
      ]);
      i--;
    }

    pdfContent.push([
      '',
      getLocaleNumber(totalContent.DYES),
      getLocaleNumber(totalContent.CHEMICALS),
      getLocaleNumber(totalContent.PACKING),
      getLocaleNumber(totalContent.CONSUMBALES),
      getLocaleNumber(totalContent.ENGINEERING_ITEM),
      getLocaleNumber(totalContent.ELECTICAL_ITEM),
      getLocaleNumber(totalContent.SERVICE_ITEMS),
      getLocaleNumber(totalContent.CAPITAL_ITEMS),
      getLocaleNumber(totalContent.FACTORY_ITEMS),
      getLocaleNumber(totalContent.total),
    ]);

    setPdfData({
      from: moment(from).format('DD/MM/YY'),
      to: moment(to).format('DD/MM/YY'),
      tableContents: pdfContent,
      tableTitles: pdfTableTitles,
    });
    setTableData(res);
    setItemData(res);
    hideLoader();
  };

  return (
    <>
      <SEO title="MachineWise-ItemWise Report" />
      <Card>
        <header>MachineWise-ItemWise Report</header>
        <CardBody>
          <Row>
            <Col breakPoint={{ xs: 12, md: 4 }}>
              <label>
                From Date <span className="red">*</span>
              </label>
              <InputGroup fullWidth size="Small">
                <input
                  type="date"
                  name="from"
                  value={from}
                  placeholder="From Date"
                  onChange={(e) => setFrom(e.target.value)}
                  className="from require"
                />
              </InputGroup>
            </Col>
            <Col breakPoint={{ xs: 12, md: 4 }}>
              <label>
                To Date <span className="red">*</span>
              </label>
              <InputGroup fullWidth size="Small">
                <input
                  type="date"
                  name="to"
                  value={to}
                  placeholder="To Date"
                  onChange={(e) => setTo(e.target.value)}
                  className="to require"
                />
              </InputGroup>
            </Col>
          </Row>
          <div
            style={{
              marginTop: '10px',
            }}
          >
            <Button
              status="Success"
              style={{
                marginRight: '10px',
              }}
              onClick={getApi}
            >
              Submit
            </Button>
          </div>
        </CardBody>
      </Card>
      <Card>
        <header>MachineWise-ItemWise Report</header>
        <CardBody>
          {tableData.length > 0 && (
            <div>
              <CSVLink data={tableData}>Download Excel</CSVLink>
              {'  '}
              <PdfGenerator documentTitle="machinewise-itemwise-abstract-report" data={pdfData} />
            </div>
          )}
          <InputGroup fullWidth size="Small">
            <input
              type="text"
              onChange={(e) => filterChange(e.target.value)}
              placeholder="Filter"
              name="tableFilter"
              value={tableFilter}
            />
          </InputGroup>
          <DataTable
            columns={columns}
            data={tableData}
            fixedHeader
            allowOverflow={false}
            overflowY={false}
            overflowX={false}
            wrap={false}
            highlightOnHover={true}
            pagination={true}
            paginationPerPage={10}
            paginationRowsPerPageOptions={[10, 20, 30, 40, 50]}
          />
        </CardBody>
      </Card>
    </>
  );
};

export default ReceiptPOCReport;
